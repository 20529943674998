<template>
  <div>
    <h3 ref="def">
      Linear Equation in One Variable
    </h3>
    <p>
      A linear equation is a polynomial equation that is linear in its constituent variable. Stated in simple words,
      it means that the highest power of the variable appearing in the equation is one.
      Let us give you an example. The equation \( 3 x + 4 =0\) is a linear equation in \(x\) since the highest power of the constituent variable i.e. \(x\)
      in the equation is one. Now, compare this to equation \(4 x^2 + 3 x + 9 =0\), which is not a linear equation in \(x\) because the highest
      power of \(x\) in the equation is greater than one.
      <br>
    </p>
    <h3 ref="sol">
      General Form of a Linear Equation
    </h3>
    <p>
      The general form of a linear equation in a variable \(x\) is given by
      $$ m x + c = l$$
      where \(m \ne 0\), \(c\) and \(l\) all are real numbers.
      <br>
    </p><h3 ref="sol">
      Solving a Linear Equation
    </h3>
    <p>
      You can imagine the two sides of equation representing two sides of a scale. The solution of the equation
      corresponds to the value of variable \(x\) that balances the scale. The value of \(x\) can be determined in two steps.
      These steps involve carrying out the same set of operations on both sides of the equation to keep the scale balanced.
    </p>
    <h5>Step#1: Subtract \(c\) from both sides</h5>
    <p>
      $$m x = l -c$$
    </p>
    <h5>Step#2: Divide both sides by \(m\)</h5>
    <p> $$x = \frac{l -c}{m}$$</p>
    <br>
    <h3 ref="pg">
      MagicGraph | Solving a Linear Equation in One Variable
    </h3>
    <p>
      The MagicGraph below offers a visually interactive tool that explains step-by-step how to solve a linear equation in one variable.
      Start by entering the values of constants m, c and l in the space provided. Tap on the next" button to move to the next step of the solution procedure.
      Tap on the previous button to move to the previous step.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
import SubtopicCard from '../../../views/EdfromEdliy.vue'
export default {
  name: 'LinearEquation',
  components: {
    SubtopicCard
  },
  data () {
    return {
      style: {layout: "margin: 10px"},
      topics: [
      {
        headline: "Ed from Edliy", description: 'Hi! I am Eve from Edliy. This playgraph will help to find the solution of a system of linear equations in two variables. Begin with entering the coefficients of the two equations that you want to solve. The playgraph will automatically plot those equations and find their point of intersection. This point of intersection represents the solution. ',
        img:"assets/teacher.svg", path: "/mathematics/sle"
      },
      ]
    }
  },
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Linear Equation in One Variable';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'System of Linear Equation',img:'/assets/number-1.svg', action: () => this.goto('def')},
      {title: 'Criterion for Existence of a Solution', img:'/assets/number-2.svg', action: () => this.goto('sol')},
      {title: 'Solving a System of Linear Equations', img:'/assets/number-3.svg', action: () => this.goto('method')},
      {title: 'Substitution Method', img:'/assets/number-4.svg', action: () => this.goto('sub')},
      {title: 'PlayGraph: Graphical Method',img:'/assets/touch.svg', action: () => this.goto('graph')}
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Linear Equation in One Variable',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about linear equation in one variable.'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
