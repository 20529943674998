<template>
  <router-link :to="path">
    <v-card
      class="topic-card"
    >
      <v-col>
        <v-flex hidden-xs-only xs12 sm6>
          <v-img
            contain
            :aspect-ratio="1"
            :src="img"
            max-height="100px"
            max-width="100px"
          />
        </v-flex>
      </v-col>
      <v-col>
        <v-card-text class="des">
          {{ description }}
        </v-card-text>
      </v-col>
    </v-card>
  </router-link>
</template>

<script>
export default {
  props: {
    headline: {
      type: String,
      default: '',
      required: true
    },
    description: {
      type: String,
      default: '',
      required: true
    },
    img: {
      type: String,
      default: '',
      required: true
    },
    path: {
      type: String,
      default: '',
      required: true
    },
  }
}
</script>
<style scoped lang="scss">
@import '@/styles/screen-sizes.scss';
.topic-card {
  border-radius: 8.5px;
  position:relative;
  background-color:moccasin ;
  //WhiteSmoke, Snow, SeaShell, OldLace
  left:20px;
  top:0px;
  @include respond-to(less-smallest-screens) { height: 250px; }
  @include respond-to(smallest-screens) { height: 250px; }
  @include respond-to(small-screens) { height:200px; width:195%; }
  @include respond-to(medium-screens) { height:150px; }
  @include respond-to(large-screens) { height: 250px;}
  @include respond-to(largest-screens) { height: 300px; }
}
.des{
font-family: Lato;
position:relative;
left:20px;
top:0px;
@include respond-to(less-smallest-screens) {font-size: 2vw;}
@include respond-to(smallest-screens) {font-size: 2vw;}
@include respond-to(small-screens) {font-size: 2vw;}
@include respond-to(medium-screens) {font-size: 2vw;}
@include respond-to(large-screens) {font-size: 1.5vw;}
@include respond-to(largest-screens) {font-size: 1.5vw;}
}
a {
  text-decoration: none !important;
}
</style>
