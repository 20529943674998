import {
    makeResponsive,
    placeTitle,
    placeImage
} from '../../../common/edliy_utils';
const Boxes = {
  box1: function () {
      JXG.Options.text.highlight=false;
      JXG.Options.text.display='internal';
      JXG.Options.text.fixed=true;
      JXG.Options.text.cssDefaultStyle = 'fontFamily:Oswald;'
      JXG.Options.text.highlightCssDefaultStyle = 'fontFamily:Oswald';
      //JXG.Options.text.anchorX='middle';
      var brd4 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-8, 8, 8, -8],keepaspectratio:true, axis:false, ticks:false, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
      //
     makeResponsive(brd4);
      //
      var arrow1 =placeImage(brd4, '/assets/curvedarrow.svg', 0.75, 0.75, 1.25, 0);
      arrow1.setAttribute({visible:()=>i>=1});
      arrow1.setLabel('Subtract it from the Right Hand Side.');
      arrow1.label.setAttribute({anchorY:'middle', offset:[70, 30]});
//
var arrow2 =placeImage(brd4, '/assets/curvedarrow.svg', -0, -1.75, 1.25, 0);
      arrow2.setAttribute({visible:()=>i>=2});
      arrow2.setLabel('Divide it from the Right Hand Side.');
      arrow2.label.setAttribute({anchorY:'middle', offset:[70, 30]});
      //Title
      //brd4.create('text',[-6.25, 7, '<b>Linear Equation in One Variable m <i>x</i> + c = d</b> '],{cssStyle:'fontFamily:Oswald;', fontSize:function(){return Math.round(22*brd4.canvasWidth/500.)}, fixed:true});
      placeTitle(brd4, 'Linear Equation in One Variable', 'Solving Equation m x + c = d');
      // Equation#1
      //var eqn1 = brd4.create('text',[-8, 3.75, 'General Form of the Equation: m x \ + \ c\ =  \ d'],{cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)}, parse:true,fixed:true});
      //var a1txt = brd4.create('text',[-7, 5, 'm = '],{cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd4.canvasWidth/500.)},fixed:true});
      var b1txt = brd4.create('text',[-2.5, 5, 'x + '],{cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd4.canvasWidth/500.)}, fixed:true});
      var c1txt = brd4.create('text',[1.5, 5., '= ' ],{cssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd4.canvasWidth/500.)},fixed:true});
      var a1 = brd4.create('input', [ -5.0, 5., 2.0, ''], {cssStyle: 'fontFamily:Oswald;width:12.5%;background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(20*brd4.canvasWidth/500.)}, fixed:true});
      var b1 = brd4.create('input', [ -1.0, 5., 4.0, ''], {cssStyle:'fontFamily:Oswald;width:12.5%;background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(20*brd4.canvasWidth/500.)},fixed:true});
      var c1 = brd4.create('input', [ 3.0, 5., 5.0, ''], {cssStyle: 'fontFamily:Oswald;width:12.5%;background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;', fontSize:function(){return Math.round(20*brd4.canvasWidth/500.)},fixed:true});
      //Discriminant
      var i =0;
	  var sci1 =brd4.create('image', ['/assets/redo.svg',[2, -6], [2,2]],{fixed:true});
	  var sci2 =brd4.create('image', ['/assets/undo.svg',[-4, -6], [2,2]],{fixed:true});
	  sci1.on('down', function(){if(i<3){return i=i+1}else{return i=3};});
	  sci1.setLabel('Next Step')
	  sci1.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
	  sci1.on('over', function () {this.label.setAttribute({visible:true});});
	  sci1.on('out', function () {this.label.setAttribute({visible:false});});
	  sci2.on('down', function(){if(i>0){return i=i-1}else{return i=0};});
	  sci2.setLabel('Previous Step')
	  sci2.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
	  sci2.on('over', function () {this.label.setAttribute({visible:true});});
	  sci2.on('out', function () {this.label.setAttribute({visible:false});});
	  a1.on('down', function(){return i=0;});
	  b1.on('down', function(){return i=0;});
	  c1.on('down', function(){return i=0;});
	  //
    var txt1 =brd4.create('text',[0, 2.5, function(){return (1.00*a1.Value()).toFixed(2) + ' x + ' + (1.00*b1.Value()).toFixed(2) + ' = ' + (1.00*c1.Value()).toFixed(2);}],{anchorX:'middle',cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd4.canvasWidth/500.)},fixed:true, visible:()=>i>=0});
	  //
	  var txt2 = brd4.create('text',[0, 0, function(){return (1.00*a1.Value()).toFixed(2) + ' x   = ' + (c1.Value()-b1.Value()).toFixed(2);}],{anchorX:'middle', cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd4.canvasWidth/500.)},fixed:true, visible:function(){if(i>=1){return true}else{return false}}});
	  //
	  var txt3 = brd4.create('text',[0, -2.5, function(){return 'x   = ' + ((c1.Value()-b1.Value())/a1.Value()).toFixed(2);}],{anchorX:'middle', cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd4.canvasWidth/500.)},fixed:true, visible:function(){if(i>=2){return true}else{return false}}});
    var ini = function(){
      txt2.setAttribute({visible:false});
      txt3.setAttribute({visible:false});
    }
    let magicGraph = document.querySelector("#jxgbox1");
    let inputFields = magicGraph.querySelectorAll("input");
    var onEachPress = function(event) {return i=0}
       inputFields.forEach(function(field) {
       field.addEventListener('input', onEachPress);});
  },
}
export default Boxes;
